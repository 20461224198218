<template>
  <b-row>
    <b-col cols="4">
      <b-card no-body>
        <b-card-header>
          <div>
            <b-card-title>รายการทดสอบ API</b-card-title>
          </div>
        </b-card-header>
        <hr class="m-0">
        <b-card-body>
          <b-row class="mb-2">
            <b-col>
              <b-link
                href="#"
                active
              >
                Get Balance
              </b-link>
            </b-col>
            <b-col class="text-right">
              <b-badge
                pill
                variant="light-danger"
              >
                ยังไม่ผ่าน
              </b-badge>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <b-link
                href="#"
                class="text-white"
              >
                Bet
              </b-link>
            </b-col>
            <b-col class="text-right">
              <b-badge
                pill
                variant="light-danger"
              >
                ยังไม่ผ่าน
              </b-badge>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <b-link
                href="#"
                class="text-white"
              >
                Cancel Bet
              </b-link>
            </b-col>
            <b-col class="text-right">
              <b-badge
                pill
                variant="light-danger"
              >
                ยังไม่ผ่าน
              </b-badge>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <b-link
                href="#"
                class="text-white"
              >
                Settle
              </b-link>
            </b-col>
            <b-col class="text-right">
              <b-badge
                pill
                variant="light-danger"
              >
                ยังไม่ผ่าน
              </b-badge>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <b-link
                href="#"
                class="text-white"
              >
                Unsettle
              </b-link>
            </b-col>
            <b-col class="text-right">
              <b-badge
                pill
                variant="light-danger"
              >
                ยังไม่ผ่าน
              </b-badge>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <b-link
                href="#"
                class="text-white"
              >
                Free Wheel
              </b-link>
            </b-col>
            <b-col class="text-right">
              <b-badge
                pill
                variant="light-danger"
              >
                ยังไม่ผ่าน
              </b-badge>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <b-link
                href="#"
                class="text-white"
              >
                Full Test
              </b-link>
            </b-col>
            <b-col class="text-right">
              <b-badge
                pill
                variant="light-danger"
              >
                ยังไม่ผ่าน
              </b-badge>
            </b-col>
          </b-row>
        </b-card-body>
        <b-card-footer>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            disabled
            block
          >
            ขอ Production Credential
          </b-button>
        </b-card-footer>
      </b-card>

      <div class="d-flex justify-content-center">
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-primary"
        >
          <feather-icon
            icon="LogInIcon"
            class="mr-50"
          />
          <span class="align-middle">เอกสารการเชื่อมต่อ API</span>
        </b-button>
      </div>
    </b-col>

    <b-col>
      <b-card no-body>
        <b-card-header>
          <div>
            <b-card-title>Get Balance</b-card-title>
          </div>
        </b-card-header>
        <hr class="m-0">
        <b-card-body>
          <b-form-group
            label="Endpoint"
            label-for="Endpoint"
            label-cols="6"
            label-class="text-secondary"
          >
            <b-form-input
              id="Endpoint"
              value="ยังไม่มีข้อมูล"
              class="text-right"
              plaintext
            />
          </b-form-group>
          <b-form-group
            label="Request Parameter 1"
            label-for="Parameter"
            label-class="text-secondary"
          >
            <b-form-input
              id="Parameter"
              value=""
              placeholder="ตัวอย่าง Request Paremeter 1"
            />
          </b-form-group>
          <b-form-group
            label="Request Parameter 2"
            label-for="Parameter"
            label-class="text-secondary"
          >
            <b-form-input
              id="Parameter"
              value=""
              placeholder="ตัวอย่าง Request Paremeter 2"
            />
          </b-form-group>
          <b-form-group
            label="Request Parameter 2"
            label-for="Parameter"
            label-class="text-secondary"
          >
            <b-form-input
              id="Parameter"
              value=""
              placeholder="ตัวอย่าง Request Paremeter 2"
            />
          </b-form-group>
          <b-row align-v="center">
            <b-col>
              <b-button variant="primary">
                ทดสอบ
              </b-button>
            </b-col>
            <b-col class="text-right">
              Response Time : - | สถานะ
              <b-badge
                pill
                variant="light-danger"
              >
                ยังไม่ผ่าน
              </b-badge>
            </b-col>
          </b-row>
          <br>
          <pre style="width: 100%; min-height: 300px;">

            <code />
            <samp>กรอก Parameter ให้ครบ แล้วกดปุ่มทดสอบ</samp>
          </pre>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {},
  props: {
    agentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.request.isGettingRequestDetail,
    }),
    ...mapGetters(['myRequests']),
    requestDetail() {
      return this.myRequests[0]
        ? this.myRequests[0]
        : { id: '', status: 0, environment: 0 }
    },
    isApproved() {
      const { status } = this.requestDetail
      return status === 2
    },
    requestId() {
      const { id } = this.requestDetail
      return id
    },
    whitelist() {
      const { whitelistIp } = this.requestDetail
      return whitelistIp?.split(',') || ['']
    },
  },
  watch: {},
  created() {
    this.fetchData()
    this.setData()
  },
  methods: {
    ...mapActions(['getRequestDetail']),
    fetchData() {
      if (this.requestId) {
        this.getRequestDetail(this.requestId)
      }
    },
    setData() {},
    onClickEdit() {
      this.$bvModal.show('edit-request-modal')
    },
    onSubmit() {
      this.$refs.customerRequestForm.validate().then(async (success) => {
        if (success) {
          //
        }
      })
    },
  },
}
</script>
